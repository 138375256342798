import { render, staticRenderFns } from "./BuyerLiftMenu.vue?vue&type=template&id=709d6ea7&scoped=true&"
import script from "./BuyerLiftMenu.vue?vue&type=script&lang=js&"
export * from "./BuyerLiftMenu.vue?vue&type=script&lang=js&"
import style0 from "./BuyerLiftMenu.vue?vue&type=style&index=0&id=709d6ea7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "709d6ea7",
  null
  
)

export default component.exports