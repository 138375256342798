<template>
  <el-row style="z-index: 2009">
    <el-col class="header">
        <el-col class="title">买家中心</el-col>
        <el-col class="group-button">
          <el-col class="user-info cursor-item" @click.native="to('/') ">首页</el-col>
          <el-col class="user-info cursor-item" @click.native="goHuawei">控制台</el-col>
<!--          <el-col class="user-info" @click.native="goPage('/')">卖家中心</el-col>-->
          <el-col class="user-info cursor-item" @click.native="goPage('/buyer/messageCenter')">通知</el-col>
          <el-col class="user-info-header cursor-item">
            <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" class="header"></el-avatar>
              <el-dropdown>
                <el-col>
                <span class="user-name">{{userName}}</span><i class="el-icon-arrow-down el-icon--right"></i>
                </el-col>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-user"   @click.native="goPage('/buyer/AddWork')">个人信息</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-unlock" @click.native="goPage('/buyer/editAccount')">修改密码</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-right"  @click.native="logOut()">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
          </el-col>
        </el-col>
    </el-col>
  </el-row>
</template>

<script>
import huaweiApi from "../../api/buyer/huawei/huaweiApi";
import CryptoJS from "crypto-js";
export default {
  name: "BuyerHeader",
  data() {
    return {
      userName: '伊登软件',
    }
  },
  created() {
    console.log(this.$store.state.userInfo.userInfo.name);
    if (this.$store.state.userInfo.userInfo.name != null){
      this.userName = this.$store.state.userInfo.userInfo.name;
    }

  },
  methods: {
    goHuawei() {
      var huaweiUrl = 'https://console.huaweicloud.com/console/?locale=zh-cn&amp;region=cn-east-3#/home'
      window.open("https://auth.huaweicloud.com/authui/saml/login?xAccountType=EDENSOFT_2013_IDP&isFirstLogin=false&service=" + encodeURIComponent(huaweiUrl));
      huaweiApi.getPortalLog({url:huaweiUrl}).then()
    },
    logOut(){
      window.sessionStorage.clear()
      // 存储token
      localStorage.clear()
      // 存储用户信息
      this.$store.commit('setUserInfo','');
      this.setCookie('', '', -1);
      //this.$router.push({path: '/'});
      this.to('/')
    },

    goPage(path) {
      // this.$store.commit('setCheckIndex', index);
      // this.$store.state.common.checkIndex = index;
      this.$router.push({path: path});
    },
    to(router) {
      this.move()
      this.$router.push({path: router})
    },
    //恢复滚动条滚动
    move(){
      document.body.style.overflow = 'visible';
    },
    setCookie(portId, psw, exdays) {
      // Encrypt，加密账号密码
      var cipherPortId = CryptoJS.AES.encrypt(
          portId + '',
          'secretkey1234567'
      ).toString()
      var cipherPsw = CryptoJS.AES.encrypt(psw + '', 'secretkey1234567').toString()
      console.log(cipherPortId + '/' + cipherPsw)// 打印一下看看有没有加密成功

      var exdate = new Date() // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) // 保存的天数
      // 字符串拼接cookie，为什么这里用了==，因为加密后的字符串也有个=号，影响下面getcookie的字符串切割，你也可以使用更炫酷的符号。
      window.document.cookie =
          'currentPortId' +
          '==' +
          cipherPortId +
          ';path=/;expires=' +
          exdate.toGMTString()
      window.document.cookie =
          'password' +
          '==' +
          cipherPsw +
          ';path=/;expires=' +
          exdate.toGMTString()
    },
  }
}
</script>

<style lang="less" scoped>
.cursor-item {
    cursor: pointer;
}
.header {
  width: 100%;
  height: 57px;
  background: #F46E64;
  box-shadow: 0px 0px 23px 1px rgba(0,0,0,0.2200);
  .title {
    width: 102px;
    //height: 24px;
    font-size: 24px;
    font-weight: 300;
    color: #FFFFFF;
    margin: 11px 0px 0px 86px;
  }
  .group-button {
    height: 100%;
    width:fit-content;
    float: right;
    margin-right: 10px;
  }
  .user-info:hover {
    color: #ffc426;
  }
  .user-info {
    width:fit-content;
    font-size: 16px;
    margin-left: 35px;
    color: #FFFFFF;
    margin-top: 17px;
    display: inline;
  }
  .user-info-header {
    width:180px;
    height: 30px;
    font-size: 16px;
    margin-left: 35px;
    color: #FFFFFF;
    margin-top: 12px;
    display: inline;
    .header {
      width: 35px;
      height: 35px ;
      line-height: 39px;
      margin-right: 10px;
    }
    .user-name {
      //margin-top: -29px;
      float: left;
      width: 65px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      //margin-top: -11px;
    }
    /deep/.el-dropdown {
      color: #ffffff;
      font-size: 16px;
      top: -6px;
      //margin-top: -11px;

    }
  }
}
</style>
