<template>
  <div>
  <buyer-header/>
  <el-row type="flex"  :gutter="0">
    <el-menu
        style="z-index: 2009"
        :default-active="defaultActive"
        class="menu-container"
        :unique-opened= "unique"
        @open="handleOpen"
        @close="handleClose"
        background-color="#FFFFFF"
        text-color="#767676"
        active-text-color="#F75145"
        @select="handleSelectMenu" >
      <el-menu-item :index="home">
        <i class="el-icon-s-data"></i>
        <span>首页</span>
      </el-menu-item>
      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-s-goods"></i>
          <span>我的订单</span>
        </template>
        <el-menu-item-group >
          <el-menu-item index="/buyer/cloudOrders">云市场订单</el-menu-item>
          <el-menu-item :index="softwareMallOrder">软件商城订单</el-menu-item>
          <el-menu-item :index="afterSale">售后管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item index="/buyer/myResources">
            <i class="el-icon-menu"></i>
            <span slot="title">我的资源</span>
      </el-menu-item>

      <el-submenu index="4">
        <template slot="title">
          <i class="el-icon-s-order"></i>
          <span>我的工单</span>
        </template>
        <el-menu-item-group >
          <!--          <template slot="title">分组一</template>-->
          <el-menu-item index="/buyer/workOrder" >我的工单</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-menu-item index="/buyer/coupon">
        <i class="el-icon-menu"></i>
        <span slot="title">我的优惠券</span>
      </el-menu-item>
      <el-menu-item index="/buyer/myFocus">
            <i class="el-icon-star-on"></i>
            <span slot="title">我的关注</span>
      </el-menu-item>
      <el-submenu index="transaction">
        <template slot="title">
          <i class="el-icon-s-finance"></i>
          <span>交易管理</span>
        </template>
      <el-menu-item-group >
        <!--          <template slot="title">分组一</template>-->
        <el-menu-item :index="transaction.unsubscribe">退订管理</el-menu-item>
        <el-menu-item :index="transaction.contract">合同管理</el-menu-item>
        <el-menu-item :index="transaction.invoice">发票管理</el-menu-item>
      </el-menu-item-group>
      </el-submenu>
      <el-submenu index="system">
        <template slot="title">
          <i class="el-icon-s-operation"></i>
          <span>系统管理</span>
        </template>
      <el-menu-item-group >
        <!--          <template slot="title">分组一</template>-->
        <el-menu-item :index="system.address">地址管理</el-menu-item>
        <el-menu-item :index="system.account">账户管理</el-menu-item>
      </el-menu-item-group>
      </el-submenu>
    </el-menu>
    <el-col class="element" style="width: 86%;height: 91vh;overflow-y: auto;overflow-x: hidden">
      <router-view :key="dateKey"></router-view>
    </el-col>
  </el-row>
  </div>
</template>

<script>
import BuyerHeader from './BuyerHeader';

export default {
  name: "/BuyerLiftMenu",
  data() {
    return {
      unique: true,
    // 买家中西首页
      home: '/buyer/BuyerIndex',
        defaultActive: window.location.href.substring(window.location.href.indexOf('#') + 1, window.location.href.length),
      // 软件商城订单
      softwareMallOrder: '/buyer/SoftwareMallOrder',
      // 云市场订单
      afterSale: '/buyer/afterSale',
      // 交易管理
      transaction: {
        // 退订管理
        unsubscribe: '/buyer/unsubscribe',
        // 发票管理
        invoice: '/buyer/invoice',
        // 合同管理
        contract: '/buyer/contract'
      },
      // 系统管理
      system: {
        address: '/buyer/address',
        account: '/buyer/account'
      },
        dateKey: new Date(),
        checkPage: '',
    }
  },
  components: {
    'buyer-header':BuyerHeader,
  },
    mounted() {
      document.body.style.overflow = 'hidden';
    },
    methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelectMenu(item) {
      console.log(item)
        this.dateKey = new Date();
        this.checkPage = item;
      document.body.style.overflow = 'hidden';
      this.$router.push({path: `${item}`});
    },
  },
  created() {
    document.body.style.overflow = 'hidden';
      // if(!this.checkPage) {
      //     let href = window.location.href;
      //     if(href.indexOf("?") > -1) {
      //         href = href.substring(href.indexOf('#') + 1, href.indexOf("?"));
      //     }else {
      //         href = href.substring(href.indexOf('#') + 1, href.length);
      //     }
      //     console.log('href', href)
      //     this.home = href;
      // }else {
      //     this.home = this.checkPage;
      // }
  }
}
</script>

<style lang="less" scoped>
// chrome 和 safari
.element::-webkit-scrollbar { width: 0 !important }
// ie10+
.element { -ms-overflow-style: none; }
// firefox
.element { overflow: -moz-scrollbars-none; }
.menu-container {
  width: 13%;
  height: 100vh;
  background: #FFFFFF;
  box-shadow: -3px 16px 19px #999;
  /deep/.el-submenu__title  {
    font-size: 15px;
  }
  /deep/ .el-menu-item{
    font-size: 15px;
  }
}

/*html,.body {
  overflow: hidden;
}*/

</style>
